import React, { useState, useEffect } from "react";
import Building from "../../assets/images/icons/building.svg";
import Profile from "../../assets/images/profile/1.jpg";
import Phone from "../../assets/images/icons/phone.svg";
import Date from "../../assets/images/icons/date.svg";
import { Col, Row, Image, InputGroup, Form } from "react-bootstrap";
import "../../assets/css/components/demandOffer.css";
import ThemeButton2 from "../Components/ThemeButton2";
import FileInput from "../Components/FileInput";
import NextArrow from "../../assets/images/icons/next.svg";

const Demanddetails = ({ title, name, desc, img, data, surname, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="demand-offer-container">
      <div className="demand-offer-area">
        <Form>
        {/* <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Teklif Dosyası (Opsiyonel)</Form.Label>
            <Form.Control className="theme-file" type="file" />
          </Form.Group> */}
          <FileInput onClick={handleFileChange} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Mesajınız</Form.Label>
            <Form.Control as="textarea" className="theme-textarea" rows={10} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Teklifiniz</Form.Label>
            <div className="d-flex align-items-center justify-content-between">
            <Form.Control type="number" placeholder="2000" className="theme-input" />
            <Form.Select className="theme-select" aria-label="Default select example">
              <option>₺</option>
              <option value="2">$</option>
              <option value="1">€</option>
              <option value="3">£</option>
            </Form.Select>
            </div>
          </Form.Group>
          <Form.Group className="mb-3 d-flex align-items-center justify-content-center">
            <ThemeButton2
              onClick={onClick}
              type={"orange"}
              text={"Teklif Yapın (40.00TL)"}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default Demanddetails;
