import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SearchArea from "../Components/SearchArea";
import ThemeCard from "../Components/ThemeCard";
import EnglandImg from "../../assets/images/banners/card1.jpeg";
import MaltaImg from "../../assets/images/banners/card2.jpeg";
import ItalyImg from "../../assets/images/banners/card3.jpeg";
import GermanyImg from "../../assets/images/banners/card4.jpeg";
import AboutImg from "../../assets/images/banners/about-img.svg";
import LinkButton from "../Components/LinkButton";
import IconCard from "../Components/IconCard";
import AcceptImg from "../../assets/images/icons/accept.svg";
import SearchWorldImg from "../../assets/images/icons/search_world.svg";
import HandleImg from "../../assets/images/icons/handle.svg";
import DemandImg from "../../assets/images/banners/demand-img.svg";
import ThemeButton from "../Components/ThemeButton";
import SearchIcon from "../../assets/images/icons/search_icon.svg";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>

      <Container className="feed-container">
        <div className="feed">
          <div className="d-flex flex-column justify-content-md-center">
            <h1></h1>
           
          </div>
          <div className="home-paragraph d-flex flex-column justify-content-md-center">
            <p>
              Öğrencilerin eğitim almak için yurtdışına gitme sürecini
              kolaylaştıran büyülü platform. Talep et teklifler gelsin.
            </p>
          </div>
          <SearchArea />
        </div>
      </Container>

      <Container fluid className="cities-container">
        <Container className="cities-area">
          <h2>Nerede eğitim almak istiyorsunuz?</h2>
          <p>
            Dil eğitiminiz için en iyi seçenekleri sizler için bir araya
            getiriyoruz.
          </p>
          <Row className="cities-card-area d-flex justify-content-center ">
            <Col xs={12} lg={3}>
              <ThemeCard title={"Malta"} count={364} img={MaltaImg} />
            </Col>
            <Col xs={12} lg={3}>
              <ThemeCard title={"İngiltere"} count={364} img={EnglandImg} />
            </Col>
            <Col xs={12} lg={3}>
              <ThemeCard title={"Almanya"} count={364} img={ItalyImg} />
            </Col>
            <Col xs={12} lg={3}>
              <ThemeCard title={"Amerika"} count={364} img={GermanyImg} />
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              className="d-flex align-items-center justify-content-start justify-content-sm-center"
            >
              <p className="cities-p ">
                Daha fazlasını mı öğrenmek istiyorsunuz?
              </p>
            </Col>
            <Col
              lg={6}
              className="d-lex align-items-center justify-content-center text-sm-center text-lg-end mt-2"
            >
              <LinkButton text="Tüm Ülkeleri Görüntüle" className="m-3" />
              <LinkButton text="Tüm Okulları Görüntüle" className="m-3" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="about-container">
        <Container className="about-area">
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={6} className="p-5">
              <h1 className="section-title">Hakkımızda</h1>
              <h2 className="section-subtitle">
                Yurtdışı eğitimde tüm{" "}
                <span className="section-span">seçenekleri</span> değerlendirin.
              </h2>
              <p className="section-text">
                Biz, eğitimle dünyayı birleştiren ve hayalleri gerçekleştirmeye
                yardımcı olan bir köprüyüz. Acadella olarak, her türden eğitim
                programına ve öğrencinin ihtiyaçlarına uygun olarak tasarlanmış
                birçok fırsata ulaşmalarını sağlamak için buradayız.
                Yükseköğrenim, dil kursları, mesleki eğitim ve daha fazlası
                dahil olmak üzere geniş bir yelpazede eğitim seçeneklerini
                özelleştirilmiş bir şekilde sunuyoruz.
              </p>
              <p className="section-text">
                Acadella, eğitim hedeflerine ulaşmaları için öğrencilere, onları
                en iyi şekilde destekleyen programları ve kurumları keşfetme
                fırsatı sunar. Biz, öğrencilerin dünyayı keşfetmelerine, bilgiyi
                paylaşmalarına ve geleceği inşa etmelerine yardımcı oluyoruz.
                Eğitimle ilgili her adımda yanınızdayız.
              </p>
            </Col>
            <Col md={6}>
              <Image className="about-img" src={AboutImg} />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="what-do-container ">
        <Container>
          <Row className="d-flex justify-content-center align-items-center ">
            <Col xs={12} lg={4}>
              <IconCard
                title={"Talep Oluştur"}
                desc={"Eğitiminiz için en uygun ve en kaliteli okullar burada."}
                img={HandleImg}
                bgColor="rgba(251, 133, 0, 0.05)"
              />
            </Col>
            <Col xs={12} lg={4}>
              <IconCard
                title={"Teklifleri Değerlendir"}
                desc={
                  "Okulların eğitiminiz ve eğitim dışında neler sunduğunu keşfedin."
                }
                img={SearchWorldImg}
                bgColor="rgba(2, 48, 71, 0.05)"
              />
            </Col>
            <Col xs={12} lg={4}>
              <IconCard
                title={"Kararını Ver"}
                desc={
                  "Son olarak kararınızı verin, istediğiniz ülkede eğitiminize başlayın!"
                }
                img={AcceptImg}
                bgColor="rgba(33, 158, 188, 0.05)"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="demand-container">
      <Container   className="demand-area">
        <Row className="what-do-area d-flex justify-content-center ">
          <Col xs={12} lg={6} className="d-flex flex-column justify-content-center align-items-center align-items-md-start  p-2">
            <h2 className="text-md-start">Geleceğiniz için sonraki adımı atın!</h2>
            <p className="text-md-start">
              Dil okullarıyla öğrencileri buluşturan yeni nesil deneyim.
              Eğitiminizin geleceği için en iyi okulları araştırmaya başlayın.
            </p>
            <ThemeButton text="Talep Et" type="orange" icon={SearchIcon} />
          </Col>
          <Col xs={12} lg={6} className="d-flex justify-content-center">
            <Image style={{ width: "100%",height:"100%" }} className="demand-img" src={DemandImg} />
          </Col>
        </Row>
      </Container>
      </Container>
    </>
  );
};

export default Home;
