import React, { useState, useEffect } from "react";
import { Col, Row, Image, InputGroup, Form } from "react-bootstrap";
import "../../assets/css/components/demandMessages.css";
import SmallButton from "../Components/SmallButton";
import NextArrow from "../../assets/images/icons/next.svg";


const Demanddetails = ({ title, name, desc, img, data, surname, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="demand-messages-container">
      <div className="demand-messages-area d-flex flex-column">
        <div className="demand-messages-item-area">
          <div className="demand-messages-item user">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              quidem
            </p>
          </div>
          <div className="demand-messages-item other-user">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              quidem Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Quisquam, quidem{" "}
            </p>
          </div>
          <div className="demand-messages-item user">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              quidem
            </p>
          </div>
          <div className="demand-messages-item other-user">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              quidem Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Quisquam, quidem{" "}
            </p>
          </div>
          <div className="demand-messages-item other-user">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              quidem Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Quisquam, quidem{" "}
            </p>
          </div>
        </div>
        <div className="demand-messages-send-area">
          <Form className="d-flex flex-row justify-content-between align-items-center">
     
            <Form.Control as="textarea" placeholder="Mesajınız buraya" className="theme-textarea messages-textarea" rows={3} />

            <SmallButton onClick={onClick} type={"light"} icon={NextArrow} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Demanddetails;
