import { Col, Container, Image, Row } from "react-bootstrap";
import AuthInput from "../Components/AuthInput";
import CustomButton from "../Components/ThemeButton3";
import acadellaicon from "../../assets/images/icons/acadellaicon.svg";
import { useNavigate } from "react-router-dom";

const CodeScreen = () => {

    const navigate = useNavigate();
    return (
    <Container fluid className="auth-container d-flex justify-content-center align-items-center">
        <div className="auth-card d-flex justify-content-center">
            <Row className="w-100 d-flex justify-content-center align-items-center">
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                    <Image src={acadellaicon}></Image>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                    <h4 className="blue">Onay Kodunu Girin</h4>
                </Col>
                <Col md={12} className="d-flex justify-content-center align-items-center">
                      <p className="about-p">Mail adresinize ve telefon numaranıza gelen onay kodunu girin..</p>
                </Col>
                <Col md={12} className="d-flex justify-content-center align-items-center">
                      <p className="about-p cursor">Kod size ulaşmadı mı? <span className="orange">Tekrar gönderin.</span></p>
                </Col>
                <Col md={12} className="d-flex justify-content-center align-items-center">
                      <AuthInput ></AuthInput>
                </Col>
                <Col md={12} className="d-flex justify-content-center align-items-center">
                      <p className="error">Kod hatalı.Lütfen yeni kod talep edin.</p>
                </Col>
                <Col md={4} xs={9} className="mt-1 mb-4 d-flex justify-content-center align-items-center">
                      <CustomButton text={"Kaydı Tamamlayın"} type={"orange"}></CustomButton>
                </Col>
            </Row>
        </div>
    </Container>
    );

}

export default CodeScreen;