import { Col, Image, Row } from "react-bootstrap";
import star from "../../assets/images/icons/star.svg";
import exampleimg from "../../assets/images/icons/exampleimage.png";
import emptystar from "../../assets/images/icons/emptystar.svg";
const CommentCard = (props) => {
    const {name,score,text,media} = props;
    const stars = Array.from({ length: 5 }, (v, i) => i + 1);
    return(
        <Row className="about-card mt-4 w-100">
            <Col md={12} className="d-flex justify-content-start flex-column">
                <p className="about-p-gray text-start m-0">{name}</p>
                <div className="d-flex align-items-center mt-2 gap-1">
                {stars.map((starIndex) => (
                    <Image
                    key={starIndex}
                    className="comment-star-med"
                    src={starIndex <= score ? star : emptystar}
                    ></Image>
                    
                ))}
                <p className="about-p-lightgray m-0">{score}</p>
                </div>
                <p className="about-p-gray text-start m-0 mt-2">{text}</p>
                
            </Col>
            <Col md={12} className="d-flex justify-content-center mb-3">
                <Row className="w-100 mt-3 d-flex justify-content-center">
                    {media.map((image,index)=>{
                        return(
                            <Col lg={4} xs={6} className="d-flex justify-content-center mt-2">
                                <Image style={{borderRadius:"20px"}} className="mw-100" src={image}></Image>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
        </Row>
    )
}
export default CommentCard;