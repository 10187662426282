import React, { useState, useEffect } from "react";
import Building from "../../assets/images/icons/building.svg";
import NextArrow from "../../assets/images/icons/next-arrow.svg";
import { Col, Row, Image } from "react-bootstrap";
import "../../assets/css/components/ThemeCard.css";


const ThemeCard = ({
  title,
  count,
  onClick,
  img
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Col onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave} className={`theme-card-container`} onClick={onClick}>
      <Row className="theme-card-area">
        <Col style={{ backgroundImage:"url("+img+")"}} className={`theme-card-image  ${isHovered ? 'hovered' : ''}`}>
        <h3  className={`theme-card-title ${isHovered ? 'title-hovered' : ''}`}>{title}</h3>
        <div className={`theme-card-title-area ${isHovered ? 'title-area-hovered' : ''}`}>
            <span className="theme-card-info"><Image src={Building} /> {count} Dil Okulu</span>
            <a className="theme-card-link">Okulları Görüntüle <Image src={NextArrow} /></a>
        </div>
        </Col>
      
      </Row>
    </Col>
  );
};

export default ThemeCard;
