import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import NextIcon from "../../assets/images/icons/next-icon2.svg";
import  "../../assets/css/components/buttons.css";
const LinkButton = (props) => {
  const {text, onClick,className} = props;


  return (
    <a className={"theme-link " + className} onClick={props.onClick}>
      {text}
      <span>
        <Image src={NextIcon} />
      </span>
    </a>
  );
};

export default LinkButton;
