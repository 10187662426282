import { Col, Image, Row } from "react-bootstrap";
import icon from "../../assets/images/icons/languagecard.svg";
const LanguageCard = (props) =>{
    const {text} = props;
    return(
        <Row className="language-card mt-3 d-flex w-100">
            <Col className="d-flex mt-2 justify-content-center" md={12}>
                <Image src={icon}></Image>
            </Col>
            <Col className="d-flex justify-content-center" md={12}>
                <p className="about-p-gray mt-2">{text}</p>
            </Col>
        </Row>
    )
}

export default LanguageCard;