import React, { useEffect, useState } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import NavbarMenu from "./NavbarMenu";
import Footer from "./Footer";


const Header = (props) => {
  const { t } = useTranslation();
  const {theme} = props;

  const cookies = new Cookies();

  return (
    <>
      <div fluid  className={"background-"+theme}>
        {/* Header Area */}
        <Container id="header">
          <NavbarMenu theme={theme} />
        </Container>
        {/* Content Area With Outlet */}
        <Outlet />

        {/* Footer Area */}
        {theme === "dark" && <Footer />}
      </div>
    </>
  );
};

export default Header;
