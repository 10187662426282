import React, { useState, useEffect } from "react";
import Building from "../../assets/images/icons/building.svg";
import Profile from "../../assets/images/profile/1.jpg";
import Phone from "../../assets/images/icons/phone.svg";
import Date from "../../assets/images/icons/date.svg";
import { Col, Row, Image } from "react-bootstrap";
import "../../assets/css/components/demandDetails.css";

const Demanddetails = ({ title, name, desc, img, data, surname, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="demand-details-container" onClick={onClick}>
      <div className="demand-details-area">
        <Row className="d-flex flex-row align-items-start justify-content-start w-100">
          <Col className="d-flex  align-items-center justify-content-start">
            <h1>
              <Image roundedCircle src={Profile} className="profile-img" />{" "}
              {name} {surname}
            </h1>
          </Col>
          <Col className="d-flex  align-items-center justify-content-end">
            <p className="nowrap">
              <Image src={Phone} className="phone-icon" /> İletişime Geç
            </p>
          </Col>
        </Row>

        <h2>{title}</h2>
      </div>
      <Row>
        {data?.map((item) => (
          <Col md={6}>
            <div className="demand-details-data-item">{item}</div>
          </Col>
        ))}
      </Row>
      <div className="demand-details-desc">
        <h2 className="text-start">Açıklama</h2>
        <p>{desc}</p>
      </div>
      <div className="demand-details-customer">
        <Row>
          <Col
            md={6}
            className="d-flex flex-row align-items-center justify-content-start"
          >
            <h2 className="text-start">Müşteri Hakkında</h2>
          </Col>
          <Col
            md={6}
            className="d-flex flex-row align-items-center justify-content-end"
          >
            <p className="text-start"> Müşteri Hakkında</p>
          </Col>
        </Row>
        <Row className="d-flex flex-row align-items-center justify-content-center">
          <Col
            md={6}
            className="d-flex flex-row align-items-center justify-content-start"
          >
            <p className="text-start">
              {" "}
              <Image src={Date} className="date-icon" /> 1 yıldır üye
            </p>
          </Col>
          <Col
            md={6}
            className="d-flex flex-row align-items-center justify-content-end"
          >
            <p className="text-start"> 10 adet iş tamamladı</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Demanddetails;
