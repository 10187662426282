import { Col, Image, Row } from "react-bootstrap";
import exampleimg from "../../assets/images/icons/exampleimage.png";
import editimgbtn from "../../assets/images/icons/editimagebtn.svg";
import checked from "../../assets/images/icons/checked.svg";
import unchecked from "../../assets/images/icons/unchecked.svg";
import { useState } from "react";
import CustomButton from "./ThemeButton3";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const Personal = () => {
    const [emailContact, setEmailContact] = useState(true);
    const [phoneContact, setPhoneContact] = useState(false);
    const [phone,setPhone] = useState('+90 555 555 55 55');
    return (
        <Row className="d-flex">
            <Col className="d-flex flex-row justify-content-center justify-content-md-start" md={6}>
                <Row className="about-card w-100">
                    <Col md={12} className="mt-3 mt-md-0">
                        <h4 className="mb-4">Kişisel Bilgileriniz</h4>
                    </Col>
                    <Col md={3} className="mt-3 mb-4 mt-md-0">
                        <div className="position-relative w-100">
                            <Image src={exampleimg} className="personal-image"></Image>
                            <Image src={editimgbtn} className="edit-image-btn"></Image>
                        </div>
                    </Col>
                    <Col md={9}></Col>
                    <Col md={6} className="d-flex flex-column">
                        <label className="person-input-label">Adınız</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={6} className="d-flex flex-column">
                        <label className="person-input-label">Soyadınız</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={6} className="mt-3 d-flex flex-column">
                        <label className="person-input-label">Email Adresiniz</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={6} className="mt-3 d-flex flex-column">
                        <label className="person-input-label">Telefon Numarınız</label>
                        <PhoneInput className="mt-2"
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone}/>
                    </Col>
                    <Col md={8} className="mt-3 mb-3">
                        <Row className="d-flex w-100">
                            <Col md={12}>
                                <label className="person-input-label mb-2">Arama Tercihleri</label>
                            </Col>
                            <Col md={12} className="position-relative">
                                <Image className="checkbox-img" {...{src: emailContact ? checked : unchecked}} onClick={() => setEmailContact(!emailContact)}></Image>
                                &nbsp;
                                <label className="person-input-label">Talep sahipleri arıyabilir</label>
                                &nbsp;
                                &nbsp;
                                <Image className="checkbox-img" {...{src: phoneContact ? checked : unchecked}} onClick={() => setPhoneContact(!phoneContact)}></Image>
                                &nbsp;
                                <label className="person-input-label">Mail ile iletişime geçilsin</label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end align-items-end">
                        <CustomButton text="Değişiklikleri Kaydet" type="orange"></CustomButton>
                    </Col>
                </Row>
            </Col>
            <Col className="d-flex flex-row justify-content-center justify-content-md-end"  md={6}>
                <Row className="about-card w-100 d-flex justify-content-center justify-content-md-end align-items-start">
                    <Col md={12} className="mt-3 mt-md-0">
                        <div className="d-flex mb-3 justify-content-between">
                            <h4>Adres Bilgileriniz</h4>
                            <p className="about-gp">Düzenlemek için adres alanına tıklayın.</p>
                        </div>
                        <textarea className="address-textarea"></textarea>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end mt-3">
                        <CustomButton text="Değişiklikleri Kaydet" type="orange"></CustomButton>
                    </Col>
                </Row>
            </Col>
            <Col className="d-flex flex-row justify-content-center justify-content-md-start" md={6}>
                <Row className="about-card w-100 d-flex justify-content-between">
                    <Col md={12} className="mt-3 mb-3 mt-md-0">
                        <h4>Şifrenizi Değiştirin</h4>
                    </Col>
                    <Col md={6} className="mt-3 mt-md-0">
                        <label className="person-input-label">Yeni Şifreniz</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={6} className="mt-3 mt-md-0">
                        <label className="person-input-label">Yeni Şifrenizi Tekrar Girin</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={6} className="mt-3 mt-md-0">
                        <label className="person-input-label">Email Adresiniz</label>
                        <input className="about-search mt-2 w-100" type="text"></input>
                    </Col>
                    <Col md={4} className="mt-3 mt-md-0 d-flex justify-content-end align-items-end">
                        <CustomButton text="Şifrenizi Güncelleyin" type="orange"></CustomButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Personal;