import { Col, Container, Image, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from 'swiper/react';
import person from "../../assets/images/icons/person.svg"
import service from "../../assets/images/icons/service.svg"
import wallet from "../../assets/images/icons/wallet.svg"
import settings from "../../assets/images/icons/settings.svg"
import help from "../../assets/images/icons/help.svg"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Service from "../Components/Service";
import { useState } from "react";
import Personal from "../Components/Personal";
import Wallet from "../Components/Wallet";
const Home = () => {
    const [selectedMenu, setSelectedMenu] = useState("personal");
    
    return (
        
        <Container className="d-flex justify-content-center flex-column">
            <Row className="about-card">
                <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1.7,
                    },
                    470:{
                        slidesPerView: 2.5,
                    },
                    768:{
                        slidesPerView: 3.5, 
                    },
                    992:{
                        slidesPerView: 5.5, 
                    },
                    1200:{
                        slidesPerView: 6,

                    },
                    1400:{
                        slidesPerView: 7,
                    }

                }}
                >
                    <SwiperSlide>
                        <p className="d-flex align-items-center profile-menu ">
                            <b>Profilim</b>
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p onClick={() => setSelectedMenu("personal")} className={"d-flex align-items-center gap-2 profile-menu"+(selectedMenu==="personal"?" selected":"")}>
                            <Image src={person}></Image>
                            Kişisel Bilgileriniz
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p onClick={() => setSelectedMenu("service")} className={"d-flex align-items-center gap-2 profile-menu "+(selectedMenu==="service"?" selected":"")}>
                            <Image src={service}></Image>
                            Hizmet Ayarları
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p onClick={() => setSelectedMenu("wallet")} className={"d-flex align-items-center gap-2 profile-menu"+(selectedMenu==="wallet"?" selected":"")}>
                            <Image src={wallet}></Image>
                            Cüzdanım
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p onClick={() => setSelectedMenu("settings")} className={"d-flex align-items-center gap-2 profile-menu"+(selectedMenu==="settings"?" selected":"")}>
                            <Image src={settings}></Image>
                            Hesap Ayarları
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p onClick={() => setSelectedMenu("help")} className={"d-flex align-items-center gap-2 profile-menu"+(selectedMenu==="help"?" selected":"")}>
                            <Image src={help}></Image>
                            Yardım ve Destek
                        </p>
                    </SwiperSlide>
                </Swiper>
            </Row>
            {/* selected menu case service show component */}
            {selectedMenu === "service" && <Service></Service>}
            {selectedMenu === "personal" && <Personal></Personal>}
            {selectedMenu === "wallet" && <Wallet></Wallet>}
        </Container>
    )
}
export default Home;