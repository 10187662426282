import { Col, Image, Row } from "react-bootstrap";
import creditlogo from "../../assets/images/icons/creditlogo.svg";
import mastercard from "../../assets/images/icons/mastercard.svg";
import walleticon from "../../assets/images/icons/walleticon.svg";
import CustomButton from "./ThemeButton3";
const Wallet = () => {

    return (
        <Row className="d-flex wallet">
            <Col className="d-flex flex-row justify-content-center justify-content-md-start" md={4}>
                <Row className="about-card w-100">
                    <Col md={12} className="mt-3 ">
                        <Image src={creditlogo}></Image>
                        <h5 className="mt-3">Krediniz</h5>
                        <h3 className="text-start credit">5.000 TL</h3>
                    </Col>
                </Row>
            </Col>
            <Col className="d-flex flex-row justify-content-center justify-content-md-start" md={8}>
                <Row className="about-card w-100">
                    <Col md={7} className="mt-3 ">
                        <Row className="gap-2">
                            <Col md={12}>
                            <h5 className="mt-3 mt-md-0">Kredi Yükleyin</h5>
                            </Col>
                            <Col md={12}>
                                <div className="card-div d-flex gap-2 align-items-center justify-content-between px-2">
                                    <Image src={mastercard}>
                                    </Image>
                                    <p className="about-gp m-0">John Doe</p>
                                    <p className="about-gp m-0">528208********65</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="card-div d-flex gap-2 align-items-center justify-content-between px-2">
                                    <Image src={mastercard}>
                                    </Image>
                                    <p className="about-gp m-0">John Doe</p>
                                    <p className="about-gp m-0">528208********65</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="card-div d-flex gap-2 align-items-center justify-content-between px-2">
                                    <Image src={mastercard}>
                                    </Image>
                                    <p className="about-gp m-0">John Doe</p>
                                    <p className="about-gp m-0">528208********65</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="card-div d-flex gap-2 align-items-center justify-content-between px-2">
                                    <Image src={mastercard}>
                                    </Image>
                                    <p className="about-gp m-0">John Doe</p>
                                    <p className="about-gp m-0">528208********65</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5} className="mt-3">
                        <Row className="d-flex">
                            <Col md={12}>
                            <h5 className="mt-3 mt-md-0">Yükleme Miktarı Seçin</h5>
                            </Col>
                            <Col md={12} className="d-flex gap-2 align-items-center mt-2">
                                <Image src={walleticon}></Image>
                                <input className="about-search w-100" type="text"></input>
                            </Col>
                            <Col md={6} className="d-flex gap-2 mt-5">
                                <CustomButton text="Yeni Kart Ekleyin"  type="orange"></CustomButton>
                            </Col>
                            <Col md={6} className="d-flex gap-2 mt-5">
                                <CustomButton text="Bakiye Yükle"  type="orange"></CustomButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row className="about-card">
                    <Col md={12}>
                        <h5 className="mt-3 mt-md-0">Hesap Özeti</h5>
                    </Col>
                    <Col md={6}>
                        <div className="gray-area mt-2">
                            <div className="border-bottom pb-2 d-flex justify-content-between">
                                <h7><b>Toplam Ödemeler:</b></h7>
                                <h7><b>3.715.00 TL</b></h7>
                            </div>
                            <div className="pt-2 d-flex justify-content-between">
                                <h7>Tamamlanan Ödemeler:</h7>
                                <h7>3.715.00 TL</h7>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="gray-area mt-2">
                            <div className="border-bottom pb-2 d-flex justify-content-between">
                                <h7><b>Toplam Harcamalar:</b></h7>
                                <h7><b>3.715.00 TL</b></h7>
                            </div>
                            <div className="pt-2 d-flex justify-content-between">
                                <h7>Teklif Verme Ücretleri:</h7>
                                <h7>3.715.00 TL</h7>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <h5 className="mt-3">Hesap Hareketleri</h5>
                    </Col>
                    <Col md={12} style={{overflowX:"auto"}}>
                        <table className="account-table">
                            <thead>
                                <tr>
                                    <th style={{borderLeft:0}}>Tarih</th>
                                    <th>İşlem Türü</th>
                                    <th>Numara</th>
                                    <th style={{borderRight:0}}>Miktar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{borderLeft:0}}>01.01.2024</td>
                                    <td>Teklif Verme Ücreti</td>
                                    <td className="text-orange"><b>0123456</b></td>
                                    <td style={{borderRight:0}}><div className="d-flex justify-content-center gap-2 align-items-center cursor">401231231231.00 TL<div className="detail-div">Detaylar</div></div></td>
                                </tr>
                                <tr>
                                    <td style={{borderLeft:0}}>01.01.2024</td>
                                    <td>Teklif Verme Ücreti</td>
                                    <td className="text-orange"><b>0123456</b></td>
                                    <td style={{borderRight:0}}><div className="d-flex justify-content-center gap-2 align-items-center cursor">40.00 TL<div className="detail-div">Detaylar</div></div></td>
                                </tr>
                                <tr>
                                    <td style={{borderLeft:0}}>01.01.2024</td>
                                    <td>Teklif Verme Ücreti</td>
                                    <td className="text-orange"><b>0123456</b></td>
                                    <td style={{borderRight:0}}><div className="d-flex justify-content-center gap-2 align-items-center cursor">40.00 TL<div className="detail-div">Detaylar</div></div></td>
                                </tr>
                                <tr>
                                    <td style={{borderLeft:0}}>01.01.2024</td>
                                    <td>Teklif Verme Ücreti</td>
                                    <td className="text-orange"><b>0123456</b></td>
                                    <td style={{borderRight:0}}><div className="d-flex justify-content-center gap-2 align-items-center cursor">40.00 TL<div className="detail-div">Detaylar</div></div></td>
                                </tr>
                                <tr>
                                    <td style={{borderLeft:0}}>01.01.2024</td>
                                    <td>Teklif Verme Ücreti</td>
                                    <td className="text-orange"><b>0123456</b></td>
                                    <td style={{borderRight:0}}><div className="d-flex justify-content-center gap-2 align-items-center cursor">40.00 TL<div className="detail-div">Detaylar</div></div></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Wallet;