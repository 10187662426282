import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/images/logos/favicon.png";
import star from "../../assets/images/icons/star.svg";
import location from "../../assets/images/icons/location.svg";
import propose from "../../assets/images/icons/case.svg"
import acctime from "../../assets/images/icons/acctime.svg"
import pro from "../../assets/images/icons/pro.svg"
import card from "../../assets/images/icons/card.svg"
import CustomButton from "../Components/ThemeButton3";
import edit from "../../assets/images/icons/edit.svg"
import LanguageCard from "../Components/LanguageCard";
import PhotoCard from "../Components/PhotoCard";
import empty from "../../assets/images/icons/empty.svg"
import exampleimg from "../../assets/images/icons/exampleimage.png"
import CommentScore from "../Components/CommentScore";
import CommentCard from "../Components/CommentCard";
import EditPopup from "./EditPopup";
import { useState } from "react";
const Service = () => {
    const [popup, setPopup] = useState(false);

    const renderPopup = () => {
        
        return(
        <EditPopup></EditPopup>
        )
    }
    
    return (
        <>
        {popup ? renderPopup() : null}
        <Row className="about-card d-flex">
                <Col md={6} lg={4} className="d-flex flex-row align-items-center gap-3">
                    <Image className="about-img" src={logo}></Image>
                    <div>
                        <h3 className="mb-3 text-start">English Culture</h3>
                        <p className="about-p text-start d-flex gap-1 align-items-center">
                            5,0
                            <Image style={{width:"15px"}} src={star}></Image>
                            <Image style={{width:"15px"}} src={star}></Image>
                            <Image style={{width:"15px"}} src={star}></Image>
                            <Image style={{width:"15px"}} src={star}></Image>
                            <Image style={{width:"15px"}} src={star}></Image>
                            (10 Yorum)
                        </p>
                        <p className="about-gp text-start">
                            <Image src={propose}></Image>
                            &nbsp;
                            10 teklif tamamlandı.
                        </p>
                    </div>
                </Col>
                <Col md={6} lg={5} className="d-flex flex-column align-items-start gap-3">
                    <h4>Hakkımızda</h4>
                    <p className="about-p text-start">
                    Lorem ipsum dolor sit amet consectetur. Facilisi malesuada et imperdiet et turpis. Enim felis vel accumsan nibh ultrices ipsum pellentesque nunc.
                    </p>
                    <div className="d-flex gap-2">
                        <p className="about-gp">
                        <img src={acctime}></img>
                        &nbsp;
                        &nbsp;
                        2 yıldır üye
                        </p>
                        <p className="about-gp">
                        <img src={pro}></img>
                        &nbsp;
                        &nbsp;
                        10 yıldır profesyonel
                        </p>
                        <p className="about-gp">
                        <img src={card}></img>
                        &nbsp;
                        &nbsp;
                        Direkt,Sisteme Ödeme
                        </p>
                    </div>
                </Col>
                <Col md={3} xs={6} className="mt-3 mt-md-0">
                    <CustomButton text="Düzenle" icon={edit} type="orange"></CustomButton>
                </Col>
            </Row>
            <Row className="mt-4 d-flex pb-4 ">
                <Col md={6} className="d-flex flex-row justify-content-center justify-content-md-start">
                    <Row className="about-card w-100">
                        <Col md={12} className="mt-3 mt-md-0">
                            <h4>Hizmetlerimiz</h4>
                        </Col>
                        <Col md={12}>
                            <Row >
                                <Col md={7} className="d-flex mb-3 mb-md-0">
                                    <input className="about-search mt-2 w-100" type="text"></input>
                                </Col>
                                <Col md={5} xs={6} className="mt-2">
                                    <CustomButton text="Değişiklikleri Kaydet"  type="orange"></CustomButton>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <LanguageCard text={"İngilizce Dil Eğitimi"}></LanguageCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center" >
                                    <LanguageCard text={"İngilizce Dil Eğitimi"}></LanguageCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <LanguageCard text={"İngilizce Dil Eğitimi"}></LanguageCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center" >
                                    <LanguageCard text={"İngilizce Dil Eğitimi"}></LanguageCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <LanguageCard text={"İngilizce Dil Eğitimi"}></LanguageCard>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="d-flex flex-row justify-content-md-end justify-content-center mt-4 mt-md-0">
                    <Row className="about-card w-100 d-flex justify-content-end">
                        <Col md={12}>
                            <Row>
                                <Col md={6} className="mt-3 mt-md-0">
                                    <h4>Fotoğraflar</h4>
                                </Col>
                                <Col md={6} className="mt-3 mt-md-0">
                                    <CustomButton text="Düzenle" icon={edit} type="orange"></CustomButton>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className="mt-5" style={{gap:"10px 0px"}}>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center" >
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center" >
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                                <Col lg={4} xs={6} className="d-flex justify-content-center">
                                    <PhotoCard image={exampleimg}></PhotoCard>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="d-flex flex-row mt-4 justify-content-center">
                    <CommentScore></CommentScore>
                </Col>
                <Col md={6} className="d-flex justify-content-center justify-content-md-end">
                    <CommentCard media={[exampleimg,exampleimg,exampleimg,exampleimg]} name={"John Doe"} score={5} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}></CommentCard>
                </Col>
                <Col md={6} className="d-flex justify-content-center justify-content-md-start">
                    <CommentCard media={[exampleimg,exampleimg]} name={"John Doe"} score={3} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}></CommentCard>
                </Col>
                <Col md={6} className="d-flex justify-content-center justify-content-md-end">
                    <CommentCard media={[exampleimg,exampleimg,exampleimg]} name={"John Doe"} score={5} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}></CommentCard>
                </Col>
            </Row>
            </>
    )
}
export default Service;