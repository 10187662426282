import React, { useState, useEffect } from "react";
import Building from "../../assets/images/icons/building.svg";
import NextArrow from "../../assets/images/icons/next-arrow.svg";
import { Col, Row, Image } from "react-bootstrap";
import "../../assets/css/components/offerCard.css";

const OfferCard = ({
  title,
  name,
  desc,
  img,
  pay_choice,
  price,
  data,
  surname,
  onClick,
  clicked,
  created_at
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const turkishMonthNames = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ];
  const date = new Date(created_at);
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const day = date.getDate();
  const month = turkishMonthNames[date.getMonth()];
  const year = date.getFullYear();
  const dateFormat = `${day} ${month} ${hours}:${minutes}`;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      className={"offer-card-container " + (clicked && " clicked")}
      onClick={onClick}
      cli
    >
        <Row className="w-100 offer-row">
          <Col className="d-flex align-items-start justify-content-start">
          <div className="offer-status"></div>
            <h1>
              {name} {surname}
            </h1>
          </Col>
          <Col className="d-flex justify-content-end align-items-en">
           <span className="offer-pay">{ dateFormat }</span>
            
          </Col>
        </Row>
      <h2>{title}</h2>
      <Row>
        {data?.map((item) => (
          <Col className="d-flex align-items-center justify-content-center">
            <span className="nowrap offer-item">{item}</span>
    
          </Col>
        ))}
        
      </Row>
          
      <Row className="w-100 offer-card-row">
        <Col className="d-flex align-items-center justify-content-start">
          <span className="offer-card-title" >Yapılan Teklif:</span>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
        <h3>{price}</h3>
        </Col>
      </Row>
      
    </div>
  );
};

export default OfferCard;
