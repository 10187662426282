const EditPopup = () => {



    return (
       <div className="popup-div">
        <div className="popup-card">
            <h3>Popup</h3>
        </div>
       </div>
    );
}
export default EditPopup;