import { useEffect, useState } from "react";
import LogoGray from "../../assets/images/logos/logo-gray.svg";
import Facebook from "../../assets/images/icons/facebook.svg";
import Twitter from "../../assets/images/icons/twitter.svg";
import Instagram from "../../assets/images/icons/instagram.svg";
import Youtube from "../../assets/images/icons/youtube.svg";
import SearchForm from "../Components/SearchForm";
import { Container, Row, Col, Image, ListGroup } from "react-bootstrap";
import "../../assets/css/footer.css";

function Footer() {
  return (
    <Container fluid className="footer-container">
      <Container className="footer-area">
        <Row className="mb-5 pt-5">
          <Col lg={6} className="d-flex align-items-center">
            <ListGroup horizontal className="footer-list-group">
              <ListGroup.Item>Ülkeler</ListGroup.Item>
              <ListGroup.Item>Tekliflerim</ListGroup.Item>
              <ListGroup.Item>Profil</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={6}>
            <SearchForm />
          </Col>
        </Row>
        <Row className="mb-5 footoer-bottom-area">
          <Col lg={8} className="d-flex align-items-center">
            <Image src={LogoGray}/>
            <ListGroup horizontal className="px-3 footer-list-group footer-list-group-bottom" variant="flush">
              <ListGroup.Item>Tüm hakları saklıdır. </ListGroup.Item>
              <ListGroup.Item>Şartlar ve koşullar</ListGroup.Item>
              <ListGroup.Item>Gizlilik sözleşmesi</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={4} className="d-flex flex-row justify-content-end footer-social-media">
            <Image src={Facebook} />
            <Image src={Twitter} />
            <Image src={Instagram} />
            <Image src={Youtube} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
