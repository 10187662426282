import React, { useState, useEffect } from "react";
import Building from "../../assets/images/icons/building.svg";
import { Col, Row, Image } from "react-bootstrap";
import "../../assets/css/components/iconCard.css";

const ThemeCard = ({ title, desc, onClick, img,bgColor }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Col
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`icon-card-container d-flex flex-column  align-items-center justify-content-center m-3  ${
        isHovered && "icon-card-hovered"
      }`}
      onClick={onClick}
    >
      <div style={{ backgroundColor:bgColor }} className={"icon-card-img d-flex justify-content-center align-items-center m-5 "+ (isHovered && "icon-card-img-hovered")}>
        <Image src={img} />
      </div>

      <h2 className="icon-card-title">{title}</h2> 
      <p className="icon-card-desc"> {desc}</p>
    </Col>
  );
};

export default ThemeCard;
