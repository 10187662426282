import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Image,ProgressBar, Alert} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../assets/css/formWizard.css";
import ThemeButton from "../Components/ThemeButton";
import NextImg from "../../assets/images/icons/next.svg";
import BackImg from "../../assets/images/icons/back.svg";

const FormWizard = (props) => {
  const [answers, setAnswers] = useState([]);
  const { t } = useTranslation();
  const [step, setStep] = useState(1);


  const data = [
    {
      title: "Yurtdışında hangi dil eğitimini almak istiyorsunuz?",
      options: [ "İngilizce", "Almanca","Arapça","İtalyanca", "Rusça"],
      multiple:false
    },
    {
      title: "Hangi ülkeye gitmek istiyorsunuz?",
      options: [ "İngiltere", "Malta","Amerika","Almanya", "İtalya","Rusya"],
      multiple:true
    },
    {
        title: "Ne kadar kalmak istiyorsunuz?",
        options: [ "4 Hafta", "6 Hafta", "12 Hafta", "18 Hafta",  "24 Hafta",],
        multiple:false
    },
    {
        title: "Konaklama türünü seçin?",
        options: [ "Aile Yanı", "Yurt", "Hotel","Farketmez"],
        multiple:false
    },
    {
        title: "Seviyenizi Seçin!",
        options: [ "A1", "A2", "B1", "B2","C1","C2"],
        multiple:false
    },
    {
      title: "Herhangi bir soru var mı?",
      options: [ "Enes"],
      multiple:false
  },
  ];

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Verileri sunucuya göndermek veya başka bir işlem yapmak için burada gerekli kodları ekleyin.
  };

  const clickOptionButton = (title, option) => {
    const updatedAnswers = [...answers];
    const existingAnswerIndex = updatedAnswers.findIndex(item => item.title === title);
    if (existingAnswerIndex !== -1) {
      if (data[step-1]?.multiple == true) {
        if (updatedAnswers[existingAnswerIndex].options.includes(option)) {
          let optionIndex = updatedAnswers[existingAnswerIndex].options.indexOf(option);
          updatedAnswers[existingAnswerIndex].options.splice(optionIndex, 1);
            
            }else{
              updatedAnswers[existingAnswerIndex].options.push(option);
            }
      }else{
        updatedAnswers[existingAnswerIndex].options = [option];
      }
    
     
    } else {
      const newAnswer = {
        title: title,
        options: [option]
      };
      updatedAnswers.push(newAnswer);
    }
    setAnswers(updatedAnswers);
    console.log(updatedAnswers);
  };




  return (
    <>
      <div className="form-wizard-area ">
        <form onSubmit={handleSubmit} className="form-wizard">
            <div className="form-wizard-header d-flex align-items-center justify-content-center ">
                <h1>Talep Detayları</h1>
            </div>
            <ProgressBar striped variant="info" animated now={100/data?.length * step} style={{ width:"100%",height:"2px" }}   />
            <Row className="form-wizard-choose d-flex align-items-center text-center justify-content-center p-3 ">
              {answers?.map((item) => (
                <Col>{item?.options?.map((answer) => (
                answer + " "
                ))}
                </Col>
              ))}
            </Row>
                <h2>{data[step-1]?.title}</h2>
                <Row className="text-center options-area mt-1">
                    {data[step-1]?.options.map((option) => (
                         <Col xs={6} md={6}>
                         <button 
                            className={answers[step-1]?.options?.includes(option) ? "active" : ""} 
                            onClick={()=>clickOptionButton(data[step-1]?.title,option)}>
                          {option}
                          </button>
                       </Col>
                    ))}
                </Row>
            <div className="form-wizard-footer">
              {step > 1 && (
                <ThemeButton onClick={handlePreviousStep} text="Geri" icon={BackImg} type={"light"} />
              )}
              {step < data.length && (
                  <ThemeButton onClick={handleNextStep} text="İleri" icon={NextImg} type={"dark"} />
              )}
              {step === data.length && (
               <ThemeButton text="Kaydet" icon={NextImg} type={"dark"} />
              )}
            </div>
        </form>
      </div>
    </>
  );
};

export default FormWizard;
