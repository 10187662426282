import React, { useState } from "react";
import  "../../assets/css/components/buttons.css";
import { Row, Col, Image } from "react-bootstrap";
const SmallButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { text, icon,type } = props;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button onClick={props.onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`btn-theme-small btn-theme-${type} ${isHovered && `btn-theme-${type}-hover`}`}>

      <div xs={4}  className={`btn-theme-icon-area btn-theme-icon-area-${type} ${isHovered && `btn-theme-icon-area-${type}-hover`}`}>
          <Image src={icon} className="btn-theme-icon " />
      </div>
    
    </button>
  );
};

export default SmallButton;
