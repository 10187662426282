import { Col, Image, Row } from "react-bootstrap";
import star from "../../assets/images/icons/star.svg";
import emptystar from "../../assets/images/icons/emptystar.svg"

const CommentScore = () => {
    return(
       
        <Row className="w-100">
        <Col md={12}>
            <h4>Yorumlar</h4>
        </Col>
        <Col md={12}>
            <Row>
                <Col md={8} className="d-flex justify-content-center">
                    <Row className="w-100">
                        <Col md={12} className="d-flex align-items-center gap-1">
                            <div className="">
                            <p className="about-p-gray d-flex align-items-center gap-1">5
                            <Image className="comment-star" src={emptystar}></Image>
                            </p>
                            </div>
                            <progress  value="100" max="100"> 100% </progress>
                        </Col>
                        <Col md={12} className="d-flex align-items-center gap-1">
                            <div className="">
                            <p className="about-p-gray d-flex align-items-center gap-1">4
                            <Image className="comment-star" src={emptystar}></Image>
                            </p>
                            </div>
                            <progress  value="0" max="100"> 0% </progress>
                        </Col>
                        <Col md={12} className="d-flex align-items-center gap-1">
                            <div className="">
                            <p className="about-p-gray d-flex align-items-center gap-1">3
                            <Image className="comment-star" src={emptystar}></Image>
                            </p>
                            </div>
                            <progress  value="0" max="100"> 0% </progress>
                        </Col>
                        <Col md={12} className="d-flex align-items-center gap-1">
                            <div className="">
                            <p className="about-p-gray d-flex align-items-center gap-1">2
                            <Image className="comment-star" src={emptystar}></Image>
                            </p>
                            </div>
                            <progress  value="0" max="100"> 0% </progress>
                        </Col>
                        <Col md={12} className="d-flex align-items-center gap-1">
                            <div className="">
                            <p className="about-p-gray d-flex align-items-center gap-1">1
                            <Image className="comment-star" src={emptystar}></Image>
                            </p>
                            </div>
                            <progress  value="0" max="100"> 0% </progress>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} className="d-flex justify-content-md-end justify-content-center">
                    <Row className="about-card d-flex justify-content-center align-items-center w-100" > 
                        <Col md={12} className="d-flex justify-content-center align-items-center">
                            <Row>
                                <Col md={12}>
                                    <h3>
                                    5,0 
                                    &nbsp;
                                    <Image style={{width:"20px"}} src={star}></Image>
                                    </h3>
                                </Col>
                                <Col md={12}>
                                    <p className="about-p-lightgray">3 yorum</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </Row>
    )
}
export default CommentScore;