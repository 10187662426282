import React, { useState } from "react";
import  "../../assets/css/components/buttons.css";
import { Row, Col, Image } from "react-bootstrap";
const CustomButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { text, icon,type } = props;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button onClick={props.onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`btn-theme btn-theme-${type} ${isHovered && `btn-theme-${type}-hover`}`}>

      <div xs={4}  className={`btn-theme-icon-area d-lg-none btn-theme-icon-area-${type} ${isHovered && `btn-theme-icon-area-${type}-hover`}`}>
          <Image src={icon} className="btn-theme-icon " />
      </div>
    <Row  className="d-none d-lg-flex">
      <Col xs={8} className="d-flex align-items-center justify-content-center">
        {text}
      </Col>
      <Col xs={4}  className={`btn-theme-icon-area btn-theme-icon-area-${type} ${isHovered && `btn-theme-icon-area-${type}-hover`}`}>
        <Image src={icon} className="btn-theme-icon" />
      </Col>
    </Row>
    </button>
  );
};

export default CustomButton;
