import React, { useState } from "react";
import "../../assets/css/components/buttons.css";
import FileIcon from "../../assets/images/icons/file-icon.svg";
const FileInput = (props) => {
  const { onClick,selectedFile,setSelectedFile } = props;

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <label htmlFor="fileInput" className="custom-file-label">
      <img src={FileIcon} />
      <div style={{}}>
        {selectedFile ? (
          <span style={{ color: "grey" }}>{selectedFile.name}</span>
        ) : (
          <p>Dosya Yükleyin (Opsiyonel)</p>
        )}
      </div>

      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </label>
  );
};

export default FileInput;
