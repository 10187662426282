import React, { useState } from "react";
import { useRegister } from "../../hooks/auth-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthInput from "../Components/AuthInput";
import acadellaicon from "../../assets/images/icons/acadellaicon.svg";
import CustomButton from "../Components/ThemeButton3";
import PhoneInput from "react-phone-number-input";

const Register = () => {
  const { t } = useTranslation();
  const { inputs, errors, handleSubmit, loading } = useRegister();
  const navigate = useNavigate();
  const [phone,setPhone] = useState('+90 555 555 55 55');
  return (
    <Container fluid className="auth-container d-flex justify-content-center align-items-center">
    <div className="auth-card d-flex justify-content-center">
        <Row className="w-100 d-flex justify-content-center align-items-center">
            <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                <Image src={acadellaicon}></Image>
            </Col>
            <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                <h4 className="blue">Kayıt Olun</h4>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <AuthInput label={"Adınız"}></AuthInput>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <AuthInput label={"Soyadınız"}></AuthInput>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column position-relative">
                  <label className="auth-input-label">Telefon Numarınız</label>
                  <PhoneInput className="mt-2 auth-input"
                  placeholder="Telefon Numarası Girin"
                  value={phone}
                  onChange={setPhone}/>
                  </div>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <AuthInput label={"Email Adresiniz"} ></AuthInput>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <AuthInput label={"Şifreniz"} type={"password"}></AuthInput>
            </Col>
            <Col md={6} className="mt-3 d-flex justify-content-center align-items-center">
                  <AuthInput label={"Şifrenizi Onaylayın"} type={"password"}></AuthInput>
            </Col>
            <Col md={9} className="mt-3 d-flex justify-content-center align-items-center">
                  <p className="error">Şifreniz en az 8 karakterden oluşmalıdır.En az bir sayı, büyük harf ve özel karakter kullanılmalıdır.</p>
            </Col>
            <Col md={4} xs={9} className="mt-1 d-flex justify-content-center align-items-center">
                  <CustomButton text={"İlerleyin"} type={"orange"}></CustomButton>
            </Col>
            <Col md={12} className="mt-1 mb-2 d-flex justify-content-center align-items-center">
                  <p className="about-p cursor">Hesabınız yok mu?&nbsp;<span onClick={() => navigate("/login")} className="orange">Giriş yapın.</span></p>
            </Col>
        </Row>
    </div>
</Container>
  );
};

export default Register;
