import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Container,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import "../../assets/css/style.css";
import { useLogin } from "../../hooks/auth-hooks";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import acadellaicon from "../../assets/images/icons/acadellaicon.svg";
import AuthInput from "../Components/AuthInput";
import CustomButton from "../Components/ThemeButton3";
const Login = () => {
  const { t } = useTranslation();
  const { inputs, errors, handleSubmit, loading } = useLogin();
  const navigate = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if (cookies.get("accessToken")) {
      navigate("/");
    }
  }, []);
  return (
    <Container fluid className="auth-container d-flex justify-content-center align-items-center">
        <div className="auth-card d-flex justify-content-center">
            <Row className="w-100 d-flex justify-content-center align-items-center">
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                    <Image src={acadellaicon}></Image>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                    <h4 className="blue">Giriş Yapın</h4>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                      <AuthInput label={"Email Adresiniz"}></AuthInput>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                      <AuthInput label={"Şifreniz"} type={"password"}></AuthInput>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                      <p className="error">Mail adresiniz ya da şifreniz yanlış</p>
                </Col>
                <Col md={4} xs={9} className="mt-3 d-flex justify-content-center align-items-center">
                      <CustomButton text={"Giriş Yapın"} type={"orange"}></CustomButton>
                </Col>
                <Col md={12} className="mt-3 d-flex justify-content-center align-items-center">
                      <p className="about-gp">Şifremi Unuttum</p>
                </Col>
                <Col md={12} className="mt-1 d-flex justify-content-center align-items-center">
                      <p className="about-p cursor">Hesabınız yok mu?&nbsp;<span onClick={() => navigate("/signup")} className="orange">Kayıt olun.</span></p>
                </Col>
            </Row>
        </div>
    </Container>
  );
};

export default Login;
