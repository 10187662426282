import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../assets/css/demands.css";
import DemandCard from "../Components/DemandCard";
import OfferCard from "../Components/OfferCard";
import DemandDetails from "../Components/DemandDetails";
import DemandOffer from "../Components/DemandOffer";
import DemandMessages from "../Components/DemandMessages";
import Card from "../../assets/images/banners/card1.jpeg";
import { use } from "i18next";

const Demands = () => {
  const { t } = useTranslation();
  const [clickedData, setClickedData] = useState(null);
  const [activeTab, setActiveTab] = useState("demands");
  const [data , setData] = useState([]);
  const [mobile, setMobile] = useState(false); 

  useEffect(() => {
    if (window.innerWidth <= 765) {
      setMobile(true);
    }else {
      setMobile(false);
    }
  },[])

  useEffect(() => {
    console.log("clickedData",clickedData);
  },clickedData)

  const test = () => {
    const container = document.getElementsByClassName("demands-container");
    const left_section = document.getElementsByClassName("left-section");
    const right_section = document.getElementsByClassName("right-section-list");
    const middle_section = document.getElementsByClassName("middle-section-list");
    if (mobile == true) {
      console.log("container",container);
      console.log("left_section",left_section);
      console.log("right_section",right_section);
      console.log("middle_section",middle_section);
      container[0].style.setProperty("height", "auto","important");
      left_section[0].style.display = "none";
      right_section[0].style.height = "auto";
      middle_section[0].style.height = "auto";
      middle_section[0].style.overflowY = "clip";
    }
  }

  const test2 = () => {
    const container2 = document.getElementsByClassName("demands-container");
    const left_section2 = document.getElementsByClassName("left-section");
    const right_section2 = document.getElementsByClassName("right-section-list");
    const middle_section2 = document.getElementsByClassName("middle-section-list");
    if (mobile == true) {
      console.log("container2",container2);
      console.log("left_section2",left_section2);
      console.log("right_section2",right_section2);
      console.log("middle_section2",middle_section2);
      container2[0].style.setProperty("height", "90vh","important");
      left_section2[0].style.display = "block";
      right_section2[0].style.height = "87vh";
      middle_section2[0].style.height = "87vh";
      middle_section2[0].style.overflowY = "scroll";

    }
  }
  const test3 = () => {
    const innerDiv = document.getElementById('left-section-scroll-position');
    innerDiv.scrollTop = 0;
  }
  const dataDemands=[
    {
      id:"ba2dadAd442",
      name:"Enes",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da İngilizce dil eğitimi almak istememin arkasındaki büyük neden, buranın güzellikleri ve denizle iç içe olma fikriydi. Derslerimizin ardından plajda zaman geçirip arkadaşlarla vakit geçirebilmek harika bir deneyim. Aynı zamanda dil öğrenme şansımızı artırıyor.",
      data:["Mail", "Malta", "12 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"ba5724CA242",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da dil eğitimi almak, sadece İngilizceyi öğrenmek değil, aynı zamanda farklı kültürleri tanımak anlamına geliyor. Sınıf arkadaşlarım dünyanın dört bir yanından geliyor ve bu, çok çeşitli bakış açılarına sahip olmamıza yardımcı oluyor.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"kla234CAd442",
      name:"Mert",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da dil eğitimi alarak kariyerimde önemli bir adım attım. İngilizceyi daha iyi konuşmak, uluslararası iş fırsatlarını artırıyor. Malta'nın sıcak iklimi ve harika okullarıyla dil öğrenmek keyifli bir deneyim.",
      data:["Telefon", "İngiltere", "4 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"b32234CAd442",
      name:"Zafer",
      surname:"Ö.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da İngilizce dil eğitimi almak, eğlenceyi ve öğrenmeyi bir araya getiriyor. Derslerden sonra adanın güzelliklerini keşfetmek, yeni insanlarla tanışmak ve İngilizcemizi geliştirmek için harika bir fırsat sunuyor.",
      data:["Telefon", "İngiltere", "4 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"b32234CAd4411",
      name:"Zafer",
      surname:"Ö.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da İngilizce dil eğitimi almak, eğlenceyi ve öğrenmeyi bir araya getiriyor. Derslerden sonra adanın güzelliklerini keşfetmek, yeni insanlarla tanışmak ve İngilizcemizi geliştirmek için harika bir fırsat sunuyor.",
      data:["Telefon", "İngiltere", "4 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"b32234CAd444",
      name:"Zafer",
      surname:"Ö.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da İngilizce dil eğitimi almak, eğlenceyi ve öğrenmeyi bir araya getiriyor. Derslerden sonra adanın güzelliklerini keşfetmek, yeni insanlarla tanışmak ve İngilizcemizi geliştirmek için harika bir fırsat sunuyor.",
      data:["Telefon", "İngiltere", "4 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"b32234CAd445",
      name:"Zafer",
      surname:"Ö.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"Malta'da İngilizce dil eğitimi almak, eğlenceyi ve öğrenmeyi bir araya getiriyor. Derslerden sonra adanın güzelliklerini keşfetmek, yeni insanlarla tanışmak ve İngilizcemizi geliştirmek için harika bir fırsat sunuyor.",
      data:["Telefon", "İngiltere", "4 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      created_at:"2023-10-28T16:16:10.178+00:00"
    }
  ];

  const dataOffers=[
    {
      id:"bssfwAd442",
      name:"Enes",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Mail", "Malta", "12 Hafta", "Yurt/Pansiyon","Sisteme Öde"],
      pay_choice:"Şirkete Ödeme",
      price:"10.000₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"basdg4CAd442",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      pay_choice:"Sisteme Ödeme",
      price:"5.000₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"yut4CAd442",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      pay_choice:"Şirkete Ödeme",
      price:"7.500₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"yut4CAd443",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      pay_choice:"Şirkete Ödeme",
      price:"7.500₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"yut4CAd444",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      pay_choice:"Şirkete Ödeme",
      price:"7.500₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    },
    {
      id:"yut4CAd445",
      name:"Sefa",
      surname:"D.",
      title:"Yurtdısı Dil Eğitimi",
      desc:"lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      data:["Telefon", "Amerika", "24 Hafta", "Aile Yanı","Farketmez"],
      pay_choice:"Şirkete Ödeme",
      price:"7.500₺",
      offer:true,
      created_at:"2023-10-28T16:16:10.178+00:00"
    }

  ];

  return (
    <>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>

      <Container className="demands-container">
        <Row>
          <Col lg={4} className="left-section">
            <Row className="left-section-header">
              <Col  xs={12} className="d-flex align-items-center justify-content-start">
                <h1 onClick={() => [setActiveTab("demands"),test3()]} className={ activeTab == "demands" ? "active" : ""}>Talepler <span>{dataDemands?.length}</span></h1>
                <h1 onClick={() => [setActiveTab("offers"),test3()]} className={ activeTab == "offers" ? "active" : ""}>Teklifler <span>{dataOffers?.length}</span></h1>
              </Col>
            </Row>
            <div className="left-section-list" id="left-section-scroll-position">
              {
               activeTab == "demands" ? (
                 dataDemands?.map((item) => (
                   <DemandCard clicked={clickedData?.id == item?.id ? true : false } {...item} onClick={() => [setClickedData(item),test()]}  />
                 ))
               ):(
                 dataOffers?.map((item) => (
                   <OfferCard clicked={clickedData?.id == item?.id ? true : false } {...item} onClick={() => [setClickedData(item),test()]} />
                 ))
               )
              }
            </div>
          </Col>
          <Col lg={4} className="middle-section" href="details">
            <Row>
              {
                mobile == true && (
                  <Col md={12}>
                    <h1 onClick={()=>test2()} className="text-start">Geri</h1>
                  </Col>
                )
              }
              <Col md={5} className="d-flex align-items-center justify-content-start">
                <h1>Detaylar</h1>
              </Col>
              <Col md={7}  className="d-flex align-items-center justify-content-end">
                <h1>#<span className="text-orange">{clickedData != null && (
                  clickedData.id
              )}</span></h1>
              </Col>
            </Row>
            <div className={"middle-section-list " + (clickedData == null && " empty")}>
              {clickedData != null && (
                   <DemandDetails {...clickedData} />
              )}
            </div>
          </Col>
          <Col lg={4} className="right-section">
            <Row>
              <Col md={12} className="d-flex align-items-center justify-content-start">
              {clickedData == null || !clickedData.offer?(
                  <h1>Teklif Ver</h1>
                ):
                (
                  <h1>Mesajlar</h1>
                )}
                
              </Col>
            </Row>
            <div className={"right-section-list " + (clickedData == null && " empty")}>
            {clickedData == null ? (
                null
              ): !clickedData.offer?(
                <DemandOffer  />
              ):
              (
                   <DemandMessages />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Demands;
