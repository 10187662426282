import { Image } from "react-bootstrap";
import showimg from "../../assets/images/icons/show.svg";
import notshowimg from "../../assets/images/icons/notshow.svg";
import { useEffect, useState } from "react";

const AuthInput = (props) => {
    const [show,setShow] = useState(false);
    const {label,type} = props;
    const [typeInput,setTypeInput] = useState(type);
    useEffect(() => {
        if(!show)
        {
            setTypeInput("password");
        }
        else{
            setTypeInput("text");
        }
    },[show])
    return (
        <div className="d-flex flex-column position-relative">
            <label className="auth-input-label">{label}</label>
            <input type={typeInput} className="auth-input">
            </input>
            {type === "password" ? 
            show ? 
            <Image onClick={() => setShow(!show)} className="show" src={notshowimg}></Image>
            :
            <Image onClick={() => setShow(!show)} className="show" src={showimg}></Image> 
            : null
            }
        </div>
    )
}
export default AuthInput;