
import ThemeButton from "../Components/ThemeButton";
import PlaneIcon from "../../assets/images/icons/plane-icon.svg";
import SearchIcon from "../../assets/images/icons/search_icon.svg";
import SchoolIcon from "../../assets/images/icons/school-icon.svg";
import { Row, Col, Image, Container } from "react-bootstrap";
import '../../assets/css/components/search.css'

const SearchArea = () => {
  return (

      <Row className="search-form-container" >
        <Col xs={12} lg={12} className="search-form-area d-flex align-items-center justify-content-start">
          <span> <Image src={SchoolIcon} /></span>
          <input
            className="search-input"
            placeholder=" Hangi eğitimi almak istiyorsun?"
          />
            <ThemeButton text="Talep Et" icon={SearchIcon} type={"dark"} onClick={() => {window.location.href = "/on-boarding"}}/>
        </Col>
      
      </Row>
  );
};

export default SearchArea;
