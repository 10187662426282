import React from "react";
import Header from "../pages/Layouts/Header";
// import Footer from "../pages/Layouts/footer";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import NoPage from "../pages/NoPage/NoPage";
import Register from "../pages/Auth/Register";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import Questions from "../pages/Questions/Questions";
import Demands from "../pages/Demands/Demands";
import Profile from "../pages/Home/Profile"
import CodeScreen from "../pages/Auth/CodeScreen";
const router = () => {
  return (
    <>
      <Routes>
        <Route element={<Header theme="dark" />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route element={<Header theme="light" />}>
          <Route path="/demands" element={<Demands />} />
          <Route path="*" element={<NoPage />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="on-boarding" element={<Questions />} />
        <Route path="signup" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="entercode" element={<CodeScreen />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Routes>
    </>
  );
};

export default router;
