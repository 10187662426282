import Lang from "../../assets/images/icons/language.svg";
import SearchItem from "../Components/SearchItem";
import SearchForm from "../Components/SearchForm";
import ThemeButton from "../Components/ThemeButton";
import LangIcon from "../../assets/images/icons/language.svg";
import IsNextIcon from "../../assets/images/icons/next-arrow.svg";
import SchoolIcon from "../../assets/images/icons/school-icon.svg";
import PlaneIcon from "../../assets/images/icons/plane-icon.svg";
import SearchIcon from "../../assets/images/icons/search_icon.svg";
import CustomButton from "./ThemeButton";
import { Row, Col, Image, Container } from "react-bootstrap";
import '../../assets/css/components/search.css'

const SearchArea = () => {
  return (
    <Container   className="search-container">
      {/* <Row className="search-categories d-flex justify-content-md-between flex-sm-column flex-md-row align-items-center">
        <SearchItem xs={12} lg={4}  iconUrl={LangIcon} isActive title="Yurtdışı Dil Eğitimi" />
        <SearchItem
        xs={12} lg={2} 
          isNext
          title="Çok Yakında!"
          isNextIcon={IsNextIcon}
        />
        <SearchItem xs={12} lg={3}  iconUrl={SchoolIcon} title="Lisans Eğitimi" />
        <SearchItem xs={12} lg={3}  iconUrl={SchoolIcon} title="Yüksek Lisans Eğitimi" />
      </Row> */}
     <SearchForm />
    </Container>
  );
};

export default SearchArea;
