import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../assets/css/formWizard.css";
import ThemeButton from "../Components/ThemeButton";
import FormWizard from "../Components/FormWizard";
import NextImg from "../../assets/images/icons/next.svg";

const OnBoarding = () => {
  
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Container
        fluid
        className="onBoarding-container d-flex align-items-center justify-content-center"
      >
        <FormWizard  />
      </Container>
    </>
  );
};

export default OnBoarding;
