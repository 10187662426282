import React, { useState } from "react";
import  "../../assets/css/components/buttons.css";
import { Row, Col, Image } from "react-bootstrap";
const CustomButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { text,type,icon,width } = props;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button 
    style={{width : width && width}}  
    onClick={props.onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={` dont btn-theme-2 btn-theme-${type} ${isHovered && `btn-theme-${type}-hover`}`}>
      {text}
      {icon &&
      <> 
      <span>&emsp;</span>
      <Image src={icon}></Image>
      </>
      }
    </button>
  );
};

export default CustomButton;
