import { useEffect,useState } from 'react';
import Logo from '../../assets/images/logos/logo.svg';
import LogoDark from '../../assets/images/logos/logo-dark.svg';
import {Container,Nav,Navbar,NavDropdown,Offcanvas,Form,Image,Button} from 'react-bootstrap';
import "../../assets/css/navbar.css";
import Profile from "../../assets/images/profile/1.jpg";
import {useNavigate} from "react-router-dom";

function OffcanvasExample(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [expand, setExpand] = useState("sm");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    // Pencere boyutu değiştiğinde handleResize fonksiyonunu çağır
      window.addEventListener('resize', handleResize);
    // Component kaldırıldığında event listener'ı temizle
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 750) {
      setExpand("sm");
    }else if (screenWidth < 992) {
      setExpand("md");
    }else if (screenWidth < 1200) {
      setExpand("lg");
    }else if (screenWidth < 1400) {
      setExpand("xl");
    }else if (screenWidth > 1400)  {
      setExpand("xxl");
    }else{
      setExpand("sm");
    }
  }, [screenWidth, screenHeight]);
  return (
    <>
        <Navbar key={expand} expand={expand} className="mb-md-3 mb-0 pt-3 text-white">
          <Container fluid>
            <Navbar.Brand onClick={() => navigate("/")} style={{cursor:"pointer"}}><Image src={props.theme === "dark" ? Logo : LogoDark} /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menü
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className={`justify-content-end flex-grow-1 pe-3 theme-nav-link-${props.theme}`}>
                  <Nav.Link href="#action1">Ülkeler</Nav.Link>
                  <Nav.Link onClick={() => navigate("/demands")}>Talepler</Nav.Link>
                  <Image roundedCircle src={Profile} className="profile-img" />
                  <NavDropdown
                    title="Profil"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item onClick={() => navigate("/profile")}>Profil</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
  );
}
export default OffcanvasExample;